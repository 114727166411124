import { useEffect, useRef, useState } from 'react'

import Image from 'next/image'

import {
  type PricesState,
  type TypeUserState,
  sendBeaconData,
  setSponsoredProductsLocalStorage,
  usePrices,
  useUser,
  useWishlist
} from '@k_frontend/core'
import {
  IconAddToCart,
  IconFlashBadgeLeftTip,
  IconHeart,
  IconHeartOrange
} from '@k_frontend/icons'
import { RatingStars } from '@k_frontend/ui'
import Stamp from 'components/Stamp'
import Tags from 'components/Tags'
import { Ellipsis } from 'components/Typograph'
import { useTimestamp } from 'hooks/useTimestamp'
import { OFFER_TYPE } from 'pages/ofertas/ofertas.types'
import {
  BACKEND_CUSTOMER_WISHLIST,
  STATIC_KABUM_IMAGES_K5
} from 'settings/kernel'
import theme from 'theme'
import { onNextImageError } from 'utils/onNextImageError'
import { pricesErrorNotify } from 'utils/pricesErrorNotify'
import { shallow } from 'zustand/shallow'

import useIsVisible from 'hooks/useIsVisible'
import { formatSendBeaconData } from 'utils/formatSendBeaconData'
import ButtonAddRemoveToCart from '../../ButtonAddRemoveToCart'
import ProductFooter from '../Footer'
import { FreeShippingTag } from '../FreeShippingTag'
import Prices from '../Prices'
import SkeletonPrices from '../Prices/SkeletonPrices'
import UnavailablePrices from '../Prices/UnavailablePrices'
import { ProductProps } from '../Product.types'
import { ProductListLoading } from './ProductList.loading'
import * as S from './ProductList.styles'

const selectorUsePrices = (state: PricesState, productId: number) => ({
  prices: state.selectorPricesDetails({ state, productId })?.prices,
  loading: state.selectorPricesDetails({ state, productId })?.loading,
  offer: state.selectorPricesDetails({ state, productId })?.offer,
  error: state.pricesDetail[productId]?.error
})

const useUserSelector = (state: TypeUserState) => ({
  isUserPrime: state.isPrime,
  clientId: state.clientId,
  session: state.session
})

export default function ProductList({
  product,
  productPathLink = 'produto',
  update,
  fnOnProductClick,
  isFullPrime,
  offerType
}: ProductProps) {
  const productListRef = useRef()
  const { isUserPrime, clientId, session } = useUser(useUserSelector, shallow)
  const [imageSrc, setImageSrc] = useState(product.thumbnail || product.image)
  const [productImageLoading, setProductImageLoading] = useState(true)
  const [onScreenProductList, setOnScreenProductList] = useState(false)
  const [wasPrintedProductList, setWasPrintedProductList] = useState(false)

  const mainProductListObserver = useIsVisible(setOnScreenProductList, {
    threshold: 0.75
  })

  const { toggleWishList, isInWishlist } = useWishlist((state) => ({
    toggleWishList: state.toggleWishList,
    isInWishlist: state.isInWishlist
  }))

  const { timestamp } = useTimestamp()

  const isExpired =
    product?.offer?.hashCode && timestamp >= product.offer?.endsAt

  const outOfStock =
    product?.offer?.hashCode && (product.quantity === 0 || update === 0)

  const stamps = product?.stamps
  const isBlackLegendary = stamps?.type === 'blackLegendary'

  const { prices, error, offer } = usePrices(
    (state) => selectorUsePrices(state, product.code),
    shallow
  )

  const { flags } = product
  const { isOffer, isPrime, isFlash, isPreOrder, isSponsored, hasGift } = flags

  const isAvailable = product?.available
  const showPrimeTag = !isUserPrime && isPrime && isOffer
  const hasOfferBadge = isOffer || getIsFullPrime()

  const isActiveOffer =
    timestamp >= product?.offer?.startsAt || timestamp >= offer?.startsAt

  const isFreeShipping =
    flags.isFreeShipping || (flags.isFreeShippingPrime && isUserPrime)

  if (productListRef.current && !wasPrintedProductList) {
    mainProductListObserver?.observe(productListRef.current)
  }

  function handleClickWishList() {
    toggleWishList({
      code: Number(product.code),
      BACKEND_CUSTOMER_WISHLIST,
      clientId,
      price:
        product?.priceWithDiscount ||
        product?.offer?.priceWithDiscount ||
        prices?.priceWithDiscount,
      session
    })
  }

  function getIsFullPrime(): boolean {
    return isFullPrime || (isPrime && isUserPrime)
  }

  const stockQuantity =
    update ||
    product?.offer?.quantityAvailable ||
    offer?.quantityAvailable ||
    product?.quantity

  function getOfferDiscount() {
    const primeDiscount =
      prices?.primeDiscountPercentage || product?.prime?.discountPercentage
    const offerDiscount =
      product?.offer?.discountPercentage || offer?.discountPercentage

    return getIsFullPrime() ? primeDiscount : offerDiscount
  }

  function renderButtonAddToCart() {
    if (offerType === OFFER_TYPE.CLOSED) {
      return (
        <S.AddToCartContainer>
          <a
            href={`/${productPathLink}/${product.code}/${product?.friendlyName}`}
          >
            <IconAddToCart color={theme.colors.black(550)} />
          </a>
        </S.AddToCartContainer>
      )
    }

    if (isAvailable && (offerType !== OFFER_TYPE.SCHEDULED || isActiveOffer)) {
      return (
        <S.AddToCartContainer>
          <ButtonAddRemoveToCart
            minimalist
            productData={product}
            disabled={error}
          />
        </S.AddToCartContainer>
      )
    }

    return null
  }

  function getProductHeader() {
    return (
      <div
        className='absolute top-0 left-0 h-40 flex gap-8 justify-end w-full p-8'
        id='productCardHeader'
      >
        <div className='flex items-center justify-between w-full'>
          <div className='flex items-center gap-8'>
            <div className={S.stampWrapper({ hasStamp: !!stamps })}>
              <Stamp stamps={stamps} />
            </div>
            <span className='flex justify-between items-center gap-8 text-xxs font-semibold text-black-800'>
              {stockQuantity && stockQuantity < 100
                ? `Restam ${stockQuantity} unid.`
                : ''}
            </span>
          </div>
          <div className='flex items-center gap-8 group-hover:hidden'>
            {product.ratingCount > 0 && (
              <div className='flex items-center gap-4'>
                <RatingStars
                  maxRating={5}
                  rating={product.rating}
                  starSize={10}
                />
                <span className='text-xxs text-black-600 leading-none pt-4'>
                  ({product.ratingCount})
                </span>
              </div>
            )}
            {isSponsored && <S.SponsoredText>Patrocinado</S.SponsoredText>}
          </div>
        </div>
        <div className='hidden items-center gap-8 group-hover:flex tablet:flex desktop:hidden'>
          <S.WishlistButton
            className='wishlistCard'
            onClick={() => handleClickWishList()}
          >
            {isInWishlist(Number(product.code)) ? (
              <IconHeartOrange height={18} />
            ) : (
              <IconHeart height={18} />
            )}
          </S.WishlistButton>

          {renderButtonAddToCart()}
        </div>
      </div>
    )
  }

  function renderTags() {
    return (
      isAvailable &&
      !error && (
        <Tags
          className='tagsCard'
          color='primary'
          code={product.code}
          small={showPrimeTag}
          isPrimeLogo={showPrimeTag}
          isPreOrder={isPreOrder}
          hasGift={hasGift}
        />
      )
    )
  }

  function getProductPrices() {
    if (!timestamp) {
      return <SkeletonPrices />
    }

    if (!isAvailable || error) {
      return <UnavailablePrices color={theme.colors.secondary(500)} />
    }

    return (
      <Prices
        productId={product.code}
        isPrime={getIsFullPrime()}
        isAvailable={isAvailable}
        product={product}
        isClosedOffer={offerType === OFFER_TYPE.CLOSED}
        outOfStock={outOfStock && offerType !== OFFER_TYPE.CLOSED}
        isScheduledOffer={
          product?.offer?.hashCode && timestamp <= product?.offer?.startsAt
        }
        offerDiscount={getOfferDiscount()}
        isActiveOffer={isActiveOffer}
        isOffer={isOffer}
        isBlackLegendary={isBlackLegendary}
      />
    )
  }

  useEffect(() => {
    pricesErrorNotify(error)
  }, [error])

  useEffect(() => {
    if (product?.newtail && onScreenProductList && !wasPrintedProductList) {
      sendBeaconData(
        product?.newtail.viewUrl,
        formatSendBeaconData(clientId, session)
      )
      setWasPrintedProductList(true)
      mainProductListObserver.disconnect()
    }
  }, [onScreenProductList])

  return (
    <div className={S.cardWrapper({ type: stamps?.type || 'normal' })}>
      <S.Product
        ref={productListRef}
        onClick={() => {
          isSponsored && setSponsoredProductsLocalStorage(product.code)
          fnOnProductClick(product)
        }}
        className='cardProdutoListagem group'
        data-smarthintproductid={product.code.toString()}
      >
        {getProductHeader()}
        <S.ProductLink
          href={`/${productPathLink}/${product.code}/${product?.friendlyName}`}
          hasOfferBadge={hasOfferBadge}
          className='productLink'
        >
          <S.Image hasNoImage={productImageLoading}>
            {productImageLoading && <ProductListLoading />}
            <Image
              src={imageSrc}
              title={product.name}
              alt={product.description}
              onError={(e) =>
                setImageSrc(
                  onNextImageError(e, STATIC_KABUM_IMAGES_K5, 'listCard')
                )
              }
              onLoad={() => setProductImageLoading(false)}
              style={{ objectFit: 'contain' }}
              width={129}
              height={129}
              priority
            />
            {isFlash && (
              <S.WrapperFlash data-testid='wrapperFlash'>
                <IconFlashBadgeLeftTip />
              </S.WrapperFlash>
            )}
          </S.Image>

          <S.Container>
            <S.Description>
              <div className='min-h-20 h-20 mb-2'>
                {isFreeShipping && <FreeShippingTag />}
              </div>
              <span>{product?.manufacturer?.name}</span>
              <h2>
                <Ellipsis lines={3}>{product.name}</Ellipsis>
              </h2>
              <S.TagsContainer>{renderTags()}</S.TagsContainer>
            </S.Description>
          </S.Container>
        </S.ProductLink>
        <S.Price>
          <S.PriceInformation
            href={`/produto/${product.code}/${product?.friendlyName}`}
          >
            {getProductPrices()}
          </S.PriceInformation>
          <ProductFooter
            product={product}
            isFullPrime={getIsFullPrime()}
            isExpired={isExpired}
            outOfStock={outOfStock}
            isBlackLegendary={isBlackLegendary}
          />
        </S.Price>
      </S.Product>
    </div>
  )
}
